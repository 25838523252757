import React from 'react';
import Colors from './Colors';
import Styles from '../../lyriccardgeneratorcss/controls.module.css';

const is = require('is_js');

class Controls extends React.Component {
  constructor(props) {
    super(props);

    this.hideCaptionOptions = false;
    this.showColorOption = false;
    this.tinySingleRow = false;
  }

  componentDidMount() {
    this.hideCaptionOptions = window.matchMedia('(max-width: 850px)').matches;
    this.showColorOption = is.desktop();
    this.transformLyricSizeText =
      this.hideCaptionOptions &&
      window.matchMedia('(max-width: 489px)').matches;
  }

  render() {
    return (
      <div
        className={
          this.props.image ? Styles.controls : Styles.controlsPlaceholder
        }
      >
        {!this.props.image && (
          <div className={Styles.placeholder}>
            <div className={Styles.placeholderText}>
              To get started, drag and drop an image, paste an image or image
              url, or use the file upload button
            </div>

            <div className={Styles.optionsGroup}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://youtu.be/2zReEaM1xBg"
                className={Styles.optionGray}
              >
                Tutorial Video
              </a>
            </div>
          </div>
        )}

        {this.props.image && (
          <div className={Styles.options}>
            {this.showColorOption && (
              <div className={Styles.optionsGroup}>
                <Colors
                  setColor={this.props.setColor}
                  image={this.props.image}
                />
              </div>
            )}

            {!this.hideCaptionOptions && (
              <>
                <div className={Styles.optionsGroup}>
                  <div
                    className={Styles.optionGray}
                    onClick={() => this.props.setCaptionSize('medium')}
                  >
                    Md Caption
                  </div>
                  <div
                    className={Styles.optionGray}
                    onClick={() => this.props.setCaptionSize('small')}
                  >
                    Sm Caption
                  </div>
                </div>

                <div className={Styles.optionsGroup}>
                  <div
                    className={`${Styles.optionGray} ${Styles.textThemeWhite}`}
                    style={{
                      margin: '0',
                    }}
                    onClick={() => this.props.setTextTheme('#ffffff')}
                  >
                    Wht caption
                  </div>
                  <div
                    className={`${Styles.optionGray} ${Styles.textThemeBlack}`}
                    onClick={() => this.props.setTextTheme('#000000')}
                  >
                    Blk caption
                  </div>
                </div>
              </>
            )}

            <div className={Styles.optionsGroup}>
              <div
                className={Styles.optionGray}
                onClick={() => this.props.setReferentFontSize('normal')}
              >
                {this.transformLyricSizeText ? 'Md' : 'Md Lyric'}
              </div>
              <div
                className={Styles.optionGray}
                onClick={() => this.props.setReferentFontSize('large')}
              >
                {this.transformLyricSizeText ? 'Lg' : 'Lg Lyric'}
              </div>
            </div>

            <div className={Styles.positionControl}>
              <div
                className={`${Styles.positionOption} ${Styles.topLeft} ${this
                  .props.referentPosition === 'top_left' &&
                  Styles.positionActive}`}
                onClick={() => this.props.setReferentPosition('top_left')}
                title="Top left"
              ></div>
              <div
                className={`${Styles.positionOption} ${Styles.topRight} ${this
                  .props.referentPosition === 'top_right' &&
                  Styles.positionActive}`}
                onClick={() => this.props.setReferentPosition('top_right')}
                title="top right"
              ></div>

              <div
                className={`${Styles.positionOption} ${
                  Styles.bottomRight
                } ${this.props.referentPosition === 'bottom_right' &&
                  Styles.positionActive}`}
                onClick={() => this.props.setReferentPosition('bottom_right')}
                title="Bottom right"
              ></div>
              <div
                className={`${Styles.positionOption} ${Styles.bottomLeft} ${this
                  .props.referentPosition === 'bottom_left' &&
                  Styles.positionActive}`}
                onClick={() => this.props.setReferentPosition('bottom_left')}
                title="Bottom left"
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Controls;
