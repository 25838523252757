import React from 'react';
import styled from 'styled-components';

class DownloadButton extends React.Component {
  constructor(props) {
    super(props);

    this.downloadButton = React.createRef();

    this.downloadCard = this.downloadCard.bind(this);
    this.resetDownload = this.resetDownload.bind(this);

    this.state = {
      filename: `lyric_card_${+new Date()}.png`,
      cardImageUrl: null,
      downloaded: false,
      downloading: false,
      aspectRatio:
        parseInt(this.props.cardHeight) / parseInt(this.props.cardWidth),
    };
  }

  componentDidMount() {
    try {
      this.html2canvas = require('html2canvas');
    } catch (e) {
      console.error(e);
    }
  }

  resetDownload() {
    URL.revokeObjectURL(this.state.cardImageUrl);

    this.setState({
      downloaded: false,
    });
  }

  canvasToObjectURL(canvas) {
    return new Promise(resolve => {
      canvas.toBlob(blob => {
        const objectUrl = URL.createObjectURL(blob);
        resolve(objectUrl);
      });
    });
  }

  downloadCard(event) {
    if (this.state.downloading) {
      this.setState({
        downloaded: true,
        downloading: false,
      });
    }

    if (!this.state.downloading) {
      event.preventDefault();

      const options = {
        scale: 1,
        logging: false,
      };

      this.html2canvas(this.props.card, options).then(canvas => {
        this.canvasToObjectURL(canvas).then(objectURL => {
          this.setState(
            {
              cardImageUrl: objectURL,
              downloaded: false,
              downloading: true,
            },
            () => {
              this.downloadButton.current.click();
            }
          );
        });
      });
    }
  }

  render() {
    return (
      <ButtonContainer
        ref={this.downloadButton}
        download={this.state.filename}
        href={this.state.cardImageUrl}
        onClick={this.downloadCard}
        style={{
          width: `${50 / this.state.aspectRatio}px`,
          right: `${this.props.offset}px`,
        }}
      >
        <svg viewBox="0 0 17.94 18.76">
          <polygon points="16.03 4.15 16.03 16.84 1.91 16.84 1.91 4.15 0 4.15 0 18.76 17.94 18.76 17.94 4.15 16.03 4.15" />
          <polygon points="9.86 11.82 9.86 0 7.95 0 7.95 11.88 4.97 8.9 3.61 10.25 8.87 15.51 14.33 10.05 12.98 8.7 9.86 11.82" />
        </svg>
      </ButtonContainer>
    );
  }
}

export default DownloadButton;

const ButtonContainer = styled.a`
  align-items: center;
  border: 2px solid #000;
  background: #fff;
  bottom: calc(2rem + 1px);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-right: 1rem;
  margin-top: 1rem;
  position: fixed;
  right: 2rem;
  z-index: 3;
  border-radius: 12px;

  svg {
    height: 20px;
    width: 20px;
    fill: #000;
  }

  @media screen and (max-width: 965px) {
    margin-right: 0;
  }
`;
