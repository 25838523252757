import React from 'react';
import styled from 'styled-components';

class ImageWarning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pixelated: false,
      outOfBounds: false,
      containerBounds: null,
    };
  }

  componentDidUpdate(prevProps) {
    const pixelated = this.isImagePixelated();
    const outOfBounds = this.runImageOutOfBounds();

    if (
      this.props.hasMoved &&
      this.props.hasZoomed &&
      (this.state.pixelated !== pixelated ||
        this.state.outOfBounds !== outOfBounds)
    ) {
      this.setState({
        pixelated: pixelated,
        outOfBounds: outOfBounds,
      });
    }
  }

  isImagePixelated() {
    const image = this.props.image.current;
    const heightPixelatedAmount = image.height / image.naturalHeight;
    const widthPixelatedAmount = image.width / image.naturalWidth;
    const pixelatedAmount = Math.max(
      heightPixelatedAmount,
      widthPixelatedAmount
    ).toFixed(2);
    return pixelatedAmount > 2 ? pixelatedAmount : false;
  }

  isImageOutOfBounds() {
    const imageBounds = this.props.image.current.getBoundingClientRect();
    const containerBounds = this.state.containerBounds;

    const xOffset = imageBounds.x - containerBounds.x;
    const isXOutOfBounds =
      imageBounds.width + xOffset < containerBounds.width || xOffset > 0;

    const yOffset = imageBounds.y - containerBounds.y;
    const isYOutOfBounds =
      imageBounds.height + yOffset < containerBounds.height || yOffset > 0;

    return isXOutOfBounds || isYOutOfBounds;
  }

  runImageOutOfBounds() {
    if (this.state.containerBounds) {
      return this.isImageOutOfBounds();
    } else {
      const imageContainer = this.props.imageContainer.current;

      this.setState(
        {
          containerBounds: imageContainer.getBoundingClientRect(),
        },
        () => this.isImageOutOfBounds
      );
    }
  }

  render() {
    return (
      <MessageContainer>
        {this.state.pixelated && (
          <MessagePill warn>Too pixelated, zoom out</MessagePill>
        )}
        {this.state.outOfBounds && (
          <MessagePill warn>Out of bounds</MessagePill>
        )}
        {!this.props.hasMoved && (
          <MessagePill>Click and drag to move the image</MessagePill>
        )}
        {!this.props.hasZoomed && (
          <MessagePill>Scroll on the image to scale it</MessagePill>
        )}
      </MessageContainer>
    );
  }
}

ImageWarning.defaultProps = {
  hasMoved: false,
  hasZoomed: false,
};

export default ImageWarning;

const MessageContainer = styled.div`
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 4;
  text-align: center;
`;

const MessagePill = styled.span`
  display: inline-block;
  border-radius: 30px;
  background: ${props => (props.warn ? '#f9362d' : '#2d7ff9')};
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding: 0.2em 0.6em;
  text-align: center;
  text-transform: uppercase;
  margin: 1em 1em 0;
`;
