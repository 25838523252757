import React from 'react';
import styled from 'styled-components';
import LyricCard from './LyricCard';
import DownloadButton from './DownloadButton';

class LyricCardHalfSize extends React.Component {
  constructor(props) {
    super(props);

    this.downloadButton = React.createRef();
    this.cardComponent = React.createRef();

    this.imagePositionUpdate = this.imagePositionUpdate.bind(this);
    this.imageZoomUpdate = this.imageZoomUpdate.bind(this);
    this.resetDownload = this.resetDownload.bind(this);

    this.state = {
      imagePosition: [0, 0],
      imageZoom: 100,
      cardRef: null,
    };
  }

  componentDidMount() {
    const cardComponentRef = this.cardComponent.current.getCardRef();
    this.setState({
      cardRef: cardComponentRef,
    });
  }

  resetDownload() {
    this.downloadButton.current.resetDownload();
  }

  imagePositionUpdate(coordinates) {
    this.setState({
      imagePosition: coordinates,
    });
  }

  imageZoomUpdate(value) {
    this.setState({
      imageZoom: value,
    });
  }

  render() {
    return (
      <CardContainer>
        <CardLabel>{this.props.label}</CardLabel>

        <DownloadButton
          ref={this.downloadButton}
          card={this.state.cardRef}
          cardWidth={this.props.width}
          cardHeight={this.props.height}
          offset={this.props.downloadButtonOffset}
        />

        <LyricCard
          width={this.props.width}
          height={this.props.height}
          image={this.props.image}
          imagePosition={this.state.imagePosition}
          onImagePositionUpdate={this.imagePositionUpdate}
          imageZoom={this.state.imageZoom}
          onImageZoomUpdate={this.imageZoomUpdate}
          color={this.props.color}
          onColorUpdate={this.props.onColorUpdate}
          caption={this.props.caption}
          captionSize={this.props.captionSize}
          onCaptionUpdate={this.props.onCaptionUpdate}
          onCaptionBlur={this.props.onCaptionBlur}
          lyric={this.props.lyric}
          onLyricUpdate={this.props.onLyricUpdate}
          resetDownload={this.resetDownload}
          referentFontSize={this.props.referentFontSize}
          referentPosition={this.props.referentPosition}
          textTheme={this.props.textTheme}
          scale=".4"
          enableWarnings="true"
          enableCheckerboard="true"
          cardSize={this.props.cardSize}
        />

        <CardOffset>
          <LyricCard
            ref={this.cardComponent}
            width={this.props.width}
            height={this.props.height}
            image={this.props.image}
            imagePosition={this.state.imagePosition}
            onImagePositionUpdate={this.imagePositionUpdate}
            imageZoom={this.state.imageZoom}
            onImageZoomUpdate={this.imageZoomUpdate}
            color={this.props.color}
            onColorUpdate={this.props.onColorUpdate}
            caption={this.props.caption}
            captionSize={this.props.captionSize}
            onCaptionUpdate={this.props.onCaptionUpdate}
            onCaptionBlur={this.props.onCaptionBlur}
            lyric={this.props.lyric}
            onLyricUpdate={this.props.onLyricUpdate}
            resetDownload={this.resetDownload}
            referentFontSize={this.props.referentFontSize}
            referentPosition={this.props.referentPosition}
            textTheme={this.props.textTheme}
            cardSize={this.props.cardSize}
          />
        </CardOffset>
      </CardContainer>
    );
  }
}

LyricCardHalfSize.defaultProps = {
  cardSize: 'small',
};

export default LyricCardHalfSize;

const CardContainer = styled.div`
  float: left;
  margin: 1rem;
  pointer-events: auto;
`;

const CardLabel = styled.div`
  color: #999;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
`;

const CardOffset = styled.div`
  position: absolute;
  right: -1000vw;
  top: 0;
`;
