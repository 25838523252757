import React from 'react';
import styled from 'styled-components';
class LyricCardPlaceholder extends React.Component {
  constructor(props) {
    super(props);

    this.setImageFromFileInput = this.setImageFromFileInput.bind(this);
    this.fileInput = React.createRef();

    this.state = {
      scale: this.props.scale,
    };
  }

  componentDidMount() {
    if (window.matchMedia('(max-width: 784px)').matches) {
      const adjustedScale =
        (window.visualViewport.width - 16) / parseInt(this.props.width);
      this.setState({
        scale: Math.min(adjustedScale, this.props.scale),
      });
    }
  }

  setImageFromFileInput() {
    const file = this.fileInput.current.files[0];
    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      this.setImageFromUrl(result);
    };

    reader.readAsDataURL(file);
  }

  setImageFromUrl(url) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        this.props.onImage(blobUrl);
      });
  }

  render() {
    return (
      <CardContainer>
        <CardLabel>{this.props.label}</CardLabel>

        <div
          style={{
            width: `calc(${this.props.width} * ${this.state.scale})`,
            height: `calc(${this.props.height} * ${this.state.scale})`,
            background: '#cccccc',
            pointerEvents: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input
            style={{ zIndex: 1, position: 'relative' }}
            type="file"
            accept="image/*"
            ref={this.fileInput}
            onChange={this.setImageFromFileInput}
          />
        </div>
      </CardContainer>
    );
  }
}

export default LyricCardPlaceholder;

const CardContainer = styled.div`
  float: left;
  margin: 1rem;
  pointer-events: auto;
`;

const CardLabel = styled.div`
  color: #999;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
`;
