import React from 'react';
import Styles from '../../lyriccardgeneratorcss/resizable_movable_image.module.css';
import { DraggableCore } from 'react-draggable';
import ImageWarning from './ImageWarning';

class ResizableMovableImage extends React.Component {
  constructor(props) {
    super(props);

    this.image = React.createRef();
    this.imageContainer = React.createRef();
    this.imageParent = React.createRef();

    this.scaleBackground = this.scaleBackground.bind(this);
    this.moveBackground = this.moveBackground.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);

    this.state = {
      imageWidth: 100,
      hasMoved: false,
      hasZoomed: false,
    };
  }

  componentDidMount() {
    this.imageContainer.current.addEventListener('wheel', this.scaleBackground);
  }

  componentWillUnmount() {
    this.imageContainer.current.removeEventListener(
      'wheel',
      this.scaleBackground
    );
  }

  componentDidUpdate() {
    if (this.state.imageWidth !== this.props.imageZoom) {
      this.setState({
        imageWidth: this.props.imageZoom,
      });
    }
  }

  imageLoaded() {
    this.props.onImageLoaded(this.image.current);
  }

  moveBackground(_, { deltaX, deltaY }) {
    const [existingX, existingY] = this.props.imagePosition;
    const coordinates = [existingX + deltaX, existingY + deltaY];

    this.setState({
      hasMoved: true,
    });

    this.props.onImagePositionUpdate(coordinates);
  }

  scaleBackground(event) {
    const newZoom = this.state.imageWidth + event.deltaY / 10;
    const [existingX, existingY] = this.props.imagePosition;
    const coordinates = [
      existingX - event.deltaY / 2,
      existingY - event.deltaY / 2,
    ];

    this.setState({
      imageWidth: newZoom,
      hasZoomed: true,
    });

    this.props.onImageZoomUpdate(newZoom);
    this.props.onImagePositionUpdate(coordinates);

    event.preventDefault();
  }

  render() {
    return (
      <div
        ref={this.imageContainer}
        className={`
          ${Styles.imageContainer}
          ${this.props.cardSize === 'large' ? Styles.imageContainerLarge : ''}
        `}
      >
        {this.props.enableWarnings && (
          <ImageWarning
            image={this.image}
            imageContainer={this.imageContainer}
            hasMoved={this.state.hasMoved}
            hasZoomed={this.state.hasZoomed}
          />
        )}

        <div>
          <DraggableCore onDrag={this.moveBackground}>
            <div
              ref={this.imageParent}
              className={Styles.sizingContainer}
              style={{
                width: `${this.state.imageWidth}%`,
                left: this.props.imagePosition[0],
                top: this.props.imagePosition[1],
              }}
            >
              <img
                draggable="false"
                ref={this.image}
                alt="background"
                src={this.props.image}
                className={Styles.image}
                onLoad={this.imageLoaded}
              />
            </div>
          </DraggableCore>
        </div>
      </div>
    );
  }
}

ResizableMovableImage.defaultProps = {
  imagePosition: [0, 0],
};

export default ResizableMovableImage;
