import React from 'react';
import Smartquotes from 'smartquotes';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Html2Text from 'html2plaintext';
import TinyColor from 'tinycolor2';
import LyricCardHalfSize from '../../components/lyriccardgenerator/LyricCardHalfSize';
import LyricCardPlaceholder from '../../components/lyriccardgenerator/LyricCardPlaceholder';
import DropSurface from '../../components/lyriccardgenerator/DropSurface';
import Controls from '../../components/lyriccardgenerator/Controls';
import '../../lyriccardgeneratorcss/body.css';

const noticeHTML = `
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1543235676306653"
     crossorigin="anonymous"></script>
<!-- Lyric Card Header -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-1543235676306653"
     data-ad-slot="1614990721"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>`;

class Tool extends React.Component {
  constructor(props) {
    super(props);

    this.setColor = this.setColor.bind(this);
    this.colorUpdate = this.colorUpdate.bind(this);
    this.imageData = this.imageData.bind(this);
    this.setCaptionSize = this.setCaptionSize.bind(this);
    this.captionUpdate = this.captionUpdate.bind(this);
    this.captionBlur = this.captionBlur.bind(this);
    this.lyricUpdate = this.lyricUpdate.bind(this);
    this.setReferentFontSize = this.setReferentFontSize.bind(this);
    this.setReferentPosition = this.setReferentPosition.bind(this);
    this.setTextTheme = this.setTextTheme.bind(this);

    this.state = {
      image: null,
      color: '#000000',
      caption: 'Artist, “Song Name”',
      lyric:
        '<div>Click to edit</div><div>Press enter to insert new lines</div><div>Or just paste some text</div>',
      referentFontSize: 'normal',
      referentPosition: 'bottom_left',
      textTheme: '#ffffff',
    };
  }

  setColor(color) {
    const textTheme = TinyColor.mostReadable(color, [
      '#000000',
      '#ffffff',
    ]).toHexString();

    this.setState({
      color,
      textTheme,
    });
  }

  setTextTheme(value) {
    this.setState({
      textTheme: value,
    });
  }

  setCaptionSize(captionSize) {
    this.setState({
      captionSize,
    });
  }

  setReferentFontSize(value) {
    this.setState({
      referentFontSize: value,
    });
  }

  setReferentPosition(value) {
    this.setState({
      referentPosition: value,
    });
  }

  lyricUpdate({ target: { value } }) {
    let newContent = value;

    if (newContent.includes('<br>')) {
      newContent = newContent.replace('<br>', '');
    }

    if (newContent.includes('<div></div>')) {
      newContent = newContent.replace('<div></div>', '');
    }

    if (!newContent.includes('<div>')) {
      newContent = `<div>${newContent}</div>`;
    }

    if (newContent.split('\n').length) {
      newContent = newContent.replace(/\n/g, '</div><div>');
    }

    this.setState({
      lyric: newContent,
    });
  }

  captionBlur({ target: { textContent } }) {
    const formattedCaption = Smartquotes(textContent);

    this.setState({
      caption: formattedCaption,
    });
  }

  colorUpdate(color) {
    this.setColor(color);
  }

  captionUpdate({ target: { value } }) {
    let plaintext = Html2Text(value);

    if (plaintext.includes('\n')) {
      plaintext = plaintext.replace('\n', ' ');
    }

    this.setState({
      caption: plaintext,
    });
  }

  imageData(imageBlobUrl) {
    this.setState({
      image: imageBlobUrl,
    });
  }

  render() {
    return (
      <>
        <DropSurface onImage={this.imageData} />

        <Controls
          image={this.state.image}
          setColor={this.setColor}
          setCaptionSize={this.setCaptionSize}
          setReferentFontSize={this.setReferentFontSize}
          setReferentPosition={this.setReferentPosition}
          referentPosition={this.state.referentPosition}
          setTextTheme={this.setTextTheme}
        />

        <NoticeContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: noticeHTML,
            }}
          />
        </NoticeContainer>

        <CardLayout>
          {!this.state.image && (
            <>
              <LyricCardPlaceholder
                width="1080px"
                height="1080px"
                label="Instagram"
                scale=".4"
                onImage={this.imageData}
              />

              <LyricCardPlaceholder
                width="1920px"
                height="1120px"
                label="Twitter"
                scale=".4"
                onImage={this.imageData}
              />
            </>
          )}

          {this.state.image && (
            <>
              <LyricCardHalfSize
                width="1080px"
                height="1080px"
                image={this.state.image}
                color={this.state.color}
                onColorUpdate={this.colorUpdate}
                caption={this.state.caption}
                captionSize={this.state.captionSize}
                onCaptionUpdate={this.captionUpdate}
                onCaptionBlur={this.captionBlur}
                lyric={this.state.lyric}
                onLyricUpdate={this.lyricUpdate}
                referentFontSize={this.state.referentFontSize}
                referentPosition={this.state.referentPosition}
                textTheme={this.state.textTheme}
                downloadButtonOffset="134"
                label="Instagram"
              />

              <LyricCardHalfSize
                width="1920px"
                height="1120px"
                image={this.state.image}
                color={this.state.color}
                onColorUpdate={this.colorUpdate}
                caption={this.state.caption}
                captionSize={this.state.captionSize}
                onCaptionUpdate={this.captionUpdate}
                onCaptionBlur={this.captionBlur}
                lyric={this.state.lyric}
                onLyricUpdate={this.lyricUpdate}
                referentFontSize={this.state.referentFontSize}
                referentPosition={this.state.referentPosition}
                textTheme={this.state.textTheme}
                cardSize="large"
                label="Twitter"
              />
            </>
          )}
        </CardLayout>

        <Helmet>
          <title>Lyric Card Maker</title>
          <body className="lyriccardgeneratortool" />
          <html lang="en" className="lyriccardgeneratortool" />
        </Helmet>
      </>
    );
  }
}

export default Tool;

const NoticeContainer = styled.div`
  display: inline-block;
  text-align: center;
  margin: 1rem 0 0;
  min-width: 100%;
  min-height: 100px;
  position: relative;
  z-index: 1;
`;

const CardLayout = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 84px);
  justify-content: center;
  pointer-events: none;
`;
