import React from 'react';
import Dragdrop from 'drag-drop';
import isImageUrl from 'is-image-url';
import Styles from '../../lyriccardgeneratorcss/drop_surface.module.css';

class DropSurface extends React.Component {
  constructor(props) {
    super(props);

    this.surface = React.createRef();

    this.state = {
      dragging: false,
    };
  }

  componentDidMount() {
    this.acceptDragFromBrowser();
    this.acceptDragFromComputer();
    this.acceptPaste();
  }

  acceptPaste() {
    document.addEventListener('paste', event => {
      const url = event.clipboardData.getData('text');

      if (isImageUrl(url)) {
        this.setImageFromUrl(url);
      }

      if (event.clipboardData.files.length) {
        this.setImageFromFile(event.clipboardData.files);
      }

      event.preventDefault();
    });
  }

  acceptDragFromBrowser() {
    this.surface.current.addEventListener('drop', event => {
      const url = event.dataTransfer.getData('text/uri-list');

      if (isImageUrl(url)) {
        this.setImageFromUrl(url);
      }

      event.preventDefault();
    });
  }

  acceptDragFromComputer() {
    Dragdrop(this.surface.current, {
      onDrop: files => {
        this.setImageFromFile(files);
      },
      onDragEnter: () => {
        this.setState({
          dragging: true,
        });
      },
      onDragLeave: () => {
        this.setState({
          dragging: false,
        });
      },
    });
  }

  setImageFromFile(files) {
    let draggedFiles = Array.from(files);

    draggedFiles.forEach(file => {
      const reader = new FileReader();

      reader.onload = ({ target: { result } }) => {
        this.setImageFromUrl(result);
      };

      reader.readAsDataURL(file);
    });
  }

  setImageFromUrl(url) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        this.props.onImage(blobUrl);
      });
  }

  render() {
    return (
      <div
        ref={this.surface}
        className={`${Styles.container} ${
          this.state.dragging ? Styles.hover : ''
        }`}
      ></div>
    );
  }
}

export default DropSurface;
